<template>
  <v-footer id="footer" class="pa-4">
    <v-row>
      <v-col md="6" cols="12" class="order-md-first order-last">
        <div class="mt-2 fz-12">© 2021 Callchain Foundation All rights reserved.</div>
      </v-col>
      <v-col md="6" cols="12">
        <div class="other d-flex justify-start justify-md-end">
          <a class="mr-4" href="https://twitter.com/callchainLedger" target="_blank"><svg width="20" height="17"><path d="M7.524 15.808l.31-4.706 8.523-7.699c.377-.345-.078-.512-.577-.211L5.26 9.856.71 8.41c-.976-.279-.987-.957.223-1.447L18.653.11c.811-.367 1.588.2 1.277 1.446l-3.018 14.252c-.211 1.012-.821 1.257-1.665.79l-4.594-3.405-2.208 2.148c-.255.255-.466.467-.92.467z"></path></svg></a>
          <a class="mr-6" href="https://t.me/callchain_group" target="_blank"><svg width="21" height="17"><path d="M20.008 1.93a8.571 8.571 0 01-2.057 2.124 11.669 11.669 0 01-.47 3.833 11.962 11.962 0 01-1.465 3.158 12.393 12.393 0 01-2.342 2.677c-.906.776-1.998 1.395-3.276 1.857-1.279.462-2.646.693-4.1.693-2.294 0-4.393-.614-6.298-1.843.296.033.626.05.99.05 1.904 0 3.602-.585 5.091-1.754a4 4 0 01-2.387-.82 4.011 4.011 0 01-1.448-2.028c.28.042.538.063.775.063.364 0 .724-.047 1.08-.14a4.035 4.035 0 01-2.356-1.417A3.969 3.969 0 01.813 5.77v-.05a4.055 4.055 0 001.854.52A4.095 4.095 0 011.334 4.78a4.02 4.02 0 01-.496-1.958c0-.746.187-1.437.56-2.072a11.614 11.614 0 003.739 3.032 11.419 11.419 0 004.716 1.265 4.602 4.602 0 01-.1-.941c0-1.136.399-2.104 1.198-2.905.8-.8 1.767-1.201 2.901-1.201 1.186 0 2.184.432 2.997 1.297a8.034 8.034 0 002.603-.991A3.99 3.99 0 0117.65 2.57a8.13 8.13 0 002.361-.637l-.003-.003z"></path></svg></a>
          <v-menu class="ml-auto" :offset-y="true" top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                  color="lightenGrey"
                  v-bind="attrs"
                  v-on="on"
                  width="148"
                  class="px-3 ml-md-0 ml-auto"
              >
                <v-icon color="black">mdi-earth</v-icon>
                <span class="black--text pr-1 text-left flex-grow-1">{{ langTxt }}</span>
                <v-icon color="black">mdi-menu-down</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                  v-for="(item, index) in langArr"
                  :key="index"
                  @click="onChangeLang(item)"
              >
                <v-list-item-title class="black--text">{{ item.text }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </v-col>
    </v-row>

  </v-footer>
</template>

<script>
  export default {
    name: 'footer',

    data: () => ({
      lang: 'en',
      langTxt: 'English',
      langArr: [{text: 'English', value: 'en'},{text: '简体中文', value: 'zh-CN'},],
    }),
    methods: {
      onChangeLang({value, text}) {
        this.lang = value;
        this.langTxt = text;

        this.$i18n.locale = value;
      },
    }
  }
</script>

<style lang="scss">
#footer {
  background-color: #fff;
  .other {
    width: 100%;
    a {
      width: 40px;
      height: 40px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      background-color: #f5f5f5;
      &:hover {
        svg {
          fill: #c91c46;
        }
      }
    }
  }
}
</style>

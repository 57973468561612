<template>
  <v-container id="bridge" class="py-md-10 py-3">
    <v-row justify="center">

      <!-- side bar -->
      <v-col md="3" class="order-md-first order-last">
        <v-card class="pa-7 d-flex flex-column view1">
          <div class="fz-24">Callchain Bridge</div>
          <div class="mt-3 fz-14 txt1">Cross chain between native CALL token of Callchain system and BEP20 CALL token of Binance Smart Chain for CALL Token</div>
          
          <nav class="link-list d-flex flex-column">
            <ol>
            <li><h4 class="link">CallBridge support transfer token from native CALL token to <a href="https://bscscan.com/token/0xcf7c87cb192bf21660b360f3f270400a42070aea" target="_blank">BEP20 CALL token</a></h4></li>
            <li><h4 class="link">We provide AMM trading function in <a href="https://exchange.pancakeswap.finance/#/swap?outputCurrency=0xcf7c87cb192bf21660b360f3f270400a42070aea" target="_blank">PancakeSwap</a> of Binance Smart Chain</h4></li>
            <li><h4 class="link">Because of gas fee, We suggest transfer minimum amount of 10000 or above</h4></li>
            <li><h4 class="link">Full instruction can be refer to this <a href="https://callchain.medium.com/callchain-system-support-cross-chain-to-binance-smart-chain-aac005fd6caf" target="_blank">tutorial</a></h4></li>
            <li><h4 class="link">Any suggestion is welcome <a href="https://t.me/callchain_group" target="_blank">Telegram</a></h4></li>
            </ol>
          </nav>
        </v-card>
      </v-col>

      <v-col md="6">
        <v-card class="view2">
          <div>
            <div class="transfer mt-6 d-flex align-center justify-space-around">
              <!-- from -->
              
              <div class="from" :class="{'order-last': crossOut}">
                <div class="label"><h2>{{ crossOut?'To':'From' }}</h2></div>
                <v-hover>
                <v-card class="cnt pa-md-4 pa-2 d-flex flex-column" elevation="3">
                  <div class="d-flex">
                    <img class="flex-grow-0 flex-shrink-0" :src="from.iconUrl" width="40" height="40"/>
                    <span class="tag ml-2" v-if="from.tag">{{ from.tag }}</span>
                  </div>
                  <div class="d-flex justify-space-between align-end mt-auto">
                    <div class="txt-4">
                      <div>{{ from.t1 }}</div>
                      <div>{{ from.t2 }}</div>
                    </div>
                  </div>
                </v-card>
                </v-hover>
              </div>
              

              <v-icon class="arr-r" @click="onTransfer">mdi-arrow-right</v-icon>
              <!-- to -->
              <div class="from" :class="{'order-first': crossOut}">
                <div class="label"><h2>{{ !crossOut?'To':'From' }}</h2></div>
                <v-hover>
                <v-card class="cnt pa-2 pa-md-4 d-flex flex-column" elevation="3">
                  <div class="d-flex">
                    <img class="flex-grow-0 flex-shrink-0" :src="to.iconUrl" width="40" height="40"/>
                    <span class="tag ml-2" v-if="to.tag">{{ to.tag }}</span>
                  </div>
                  <div class="d-flex justify-space-between align-end mt-auto">
                    <div class="txt-4">
                      <div>{{ to.t1 }}</div>
                    </div>
                  </div>
                </v-card>
                </v-hover>
              </div>
            </div>
            <div v-if="crossOut">
              <div class="mny mt-6">
                <div class="label fz-12"><h3>Recipient (Callchain Address)</h3> </div>
                <v-text-field height="80" class="ipt" outlined hide-details v-model="recipient"></v-text-field>
              </div>
              <div class="mny mt-6">
                <div class="label fz-12"><h3>Amount (Balance: {{balance}})</h3></div>
                <v-text-field height="80" class="ipt" outlined hide-details v-model="amount"></v-text-field>
              </div>
            </div>
            <div v-else class="mt-6 pb-6 pt-6" style="line-height: 2">
              When you need to convert Callchain native CALL token to BEP20 CALL token. You should open <a href="https://wallet.callchain.cc/#/" target="_blank">CallWallet</a>, 
              and send CALL to cross chain address: <a href="http://block.callchain.cc/#/account/chkw6nVJ1g1APQ1vA5EX9ek5Vb4ZGrckwP" target="_blank">cPUUpqDUKCDUyokyX4w2Hg3oHspjwnkZTY</a>
              with your memo to your target BEP20 CALL token recipient.
              <br>
              You can refer to this <a href="https://callchain.medium.com/callchain-system-support-cross-chain-to-binance-smart-chain-aac005fd6caf" target="_blank">full instructions</a>.
            </div>
            <v-btn class="mt-6 primary" height="40" block @click="onConnect"><span class="white--text">
              {{!crossOut ? 'I Understand It' : !connect ? 'Connect Wallet' : btnText}}
            </span></v-btn>

            <v-snackbar v-model="snackbar" :color="success" class="pt-12" timeout="1000">
              {{snackText}}
              <template v-slot:action="{ attrs }">
              <v-btn
                text
                v-bind="attrs"
                @click="snackbar = false"
              >
                Close
              </v-btn>
            </template>
              </v-snackbar>
          </div>
        </v-card>
      </v-col>
    </v-row>

  </v-container>
</template>

<script>
import {mapState} from 'vuex'
import api from '../api/index'
import BN from 'bignumber.js'
const MIN_AMOUNT = new BN(10000e6);

export default {
  name: "Bridge",
  data() {
    return {
      crossOut: true,

      snackbar: false,
      success: 'green',
      snackText: 'Welcome',

      recipient: '',
      amount: '',
      btnText: 'Approve',

      chData: {},
      chIdx: 0,
      from: {iconUrl: require('@/assets/img/to.png'), label: 'To', tag: 'Native CALL', t1: 'Callchain System'},
      to: {
        iconUrl: require('@/assets/img/from.svg'),
        label: 'From',
        tag: 'BEP20 CALL',
        t1: 'Binance Smart Chain',
      },
    }
  },
  computed: mapState({
      connect: (state) => state.connect,
      address: (state) => state.address,
      balance: (state) => state.balance
  }),

  methods: {
    showError(msg) {
      this.snackbar = true;
      this.success = 'red';
      this.snackText = msg;
    },
    showSuccess(msg) {
      this.snackbar = true;
      this.success = 'green';
      this.snackText = msg;
    },
    async onConnect() {
      let self = this;
      if (!self.crossOut) {
        self.showError('Good Luck. Enjoy of Binance Smart Chain.');
        return;
      }
      // login
      if (!self.connect) {
        let ret = await api.getAccountInfo('metamask');
        if (typeof ret === 'string') {
          self.showError(ret);
        } else {
          this.$store.dispatch('login', ret);
        }
        return;
      }
      if ( !api.isValidAddr(self.recipient) 
            || isNaN(self.amount) 
            || Number(self.amount) <=0 
            || new BN(self.amount).isGreaterThan(self.balance) ) {
        self.showError("Invalid Parameters");
        return;
      }

      let hash = undefined;
      try {
        if (self.btnText === 'Approve') {
          hash = await api.approve(new BN(self.amount), self.address);
          self.showSuccess("Approve Submit Success");
        }

        if (self.btnText === 'Confirm') {
          let _a = new BN(self.amount);
          if (_a.isLessThan(MIN_AMOUNT)) {
            self.showError("Min amount is 10000");
            return;
          }
          hash = await api.goHome(self.recipient, new BN(self.amount), self.address);
          self.showSuccess("GoHome Submit Success");
        }

        if (hash) {
          let timer = setInterval(async function() {
            let success = await api.checkTransaction(hash);
            if (success) {
              self.showSuccess("Welcome to Callchain System");
              clearInterval(timer);
              await self.checkBtnText();
            }
            // TODO if failed?
          }, 1000);
        }
      } catch(e) {
        console.error(e);
      }
    },
    async checkBtnText() {
      if (this.btnText === 'Approve') {
        let self = this;
        let _address = self.address
        let isAllowed = await api.checkAllowance(_address, new BN(self.amount));
        self.btnText = isAllowed ? 'Confirm' : 'Approve';
      }
    },

    onTransfer() {
      this.crossOut = !this.crossOut
    }
  },
  created() {
    let self = this;
    setInterval(async function() {
      if (!self.connect
          || !api.isValidAddr(self.recipient) 
          || isNaN(self.amount) 
          || Number(self.amount) <=0 
          || new BN(self.amount).isGreaterThan(self.balance) ) {
        return;
      }
      let _address = self.address;
      let isAllowed = await api.checkAllowance(_address, new BN(self.amount));
      self.btnText = isAllowed ? 'Confirm' : 'Approve';
    }, 2000);
  },
  watch: {
    async recipient(val,oldval) {
      let self = this;
      if (!self.connect) return;
      if (self.recipient === '') return;

      if (!api.isValidAddr(val)) {
        self.showError("Invalid Recipient Address");
        self.recipient = '';
      }
    },
    async amount(val,oldval) {
      let self = this;
      if (!self.connect) return;
      if (self.amount === '') return;

      if (isNaN(val) || Number(val) < 0)
      {
        self.showError("Invalid Amount");
        self.amount = '';
        return;
      }

      if (new BN(val).isGreaterThan(self.balance)) {
        self.showError("Insufficient Balance");
        self.amount = self.balance.toNumber();
        return;
      }

      let _address = self.address;
      let isAllowed = await api.checkAllowance(_address, new BN(self.amount));
      self.btnText = isAllowed ? 'Confirm' : 'Approve';
    }
  }
}
</script>

<style lang="scss">

#bridge {

  .txt1 {
    color: #76808f;
    // margin-bottom: 20px;
  }

  .txt2 {
    width: 160px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f5f5f5;
    color: #333;
    border-radius: 8px;
    cursor: pointer;

    &:hover {
      background-color: #c91c46;
      color: #fff;

      .v-icon {
        color: #fff !important;
      }
    }
  }

  .link-list {
    font-size: 14px;

    .link {
      line-height: 18px;
      margin-top: 16px;
      text-decoration: none;
    }
  }

  .view1, .view2 {
    height: 582px;
    border-radius: 16px;
  }

  .view2 {
    padding: 40px 80px;
  }

  .txt3 {
    .label {
      font-size: 12px;
      color: #76808f;
      text-align: center;
    }

    .progress {
      width: 80px;
      height: 6px;
      margin: 6px auto;
    }
  }

  .select-box {
    margin-top: 40px;

    .label {
      font-size: 12px;
    }

    .select {
      height: 40px;
      padding: 0 14px;
      background-color: #f5f5f5;
      border-radius: 8px;
    }
  }

  .transfer {
    font-size: 14px;

    .from, .to {
      flex: 1 1;
    }

    .arr-r {
      width: 40px;
      height: 40px;
      background-color: #f5f5f5;
      border-radius: 8px;
      cursor: pointer;
      flex: 0 0 40px;
      margin: 0 14px;

      // &:hover {
        // background-color: #c91c46;
      // }
    }

    .tag {
      padding: 3px 6px;
      background-color: lighten(#c91c46, 50%);
      color: #c91c46;
      font-size: 12px;
      height: 20px;
      border-radius: 9px;
    }

    .v-card {
      height: 124px;
    }

    .txt-4 {
      line-height: 1.2;
    }

    .ico-dwn {
      width: 24px;
      height: 24px;
      text-align: center;
      line-height: 24px;
      background-color: #f5f5f5;
    }
  }

  .mny {
    .ipt {
      font-size: 16px;

      fieldset {
        border-color: transparent;
        background-color: #f5f5f5;
      }

      &.v-input--is--focused {
        border: 1px solid #c91c46;
      }
    }
  }

  @media screen and (max-width: 1000px) {
    .view1, .view2 {
      padding: 24px 12px;
      height: auto;
    }
    .from {
      font-size: 12px;

      .v-card {
        height: 100px;
      }
    }
  }
}

.sel-tit {
  border-radius: 10px;
}

.modal-sel {
  border-radius: 16px;
}

.sel-list {
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  max-height: 300px;
  overflow-y: scroll;

  &-item {
    border-bottom: 1px solid #f5f5f5;

    &:hover {
      background-color: lighten(#c91c46, 50%);
    }
  }
}

.chk-list {
  max-height: none;
  overflow-y: auto;

  .sel-list-item {
    width: 258px;
    padding: 0 24px;
    height: 40px;
    border-bottom: 2px solid #000;
  }
}

</style>
<template>
  <v-app id="app">
    <v-app-bar class="white flex-grow-0 v-app-bar--hide-shadow">
      <div class="d-flex">
        <v-img src="@/assets/img/logo.png" width="120"></v-img>
      </div>
      <v-toolbar-items class="hidden-sm-and-down">
        <v-btn
                v-for="(link, key) in links"
                :key="key"
                text
                @click="onRoute(link.url, key)"
                class="nav"
        >
          <a href="" class="text-decoration-none black--text">
            {{ $t(`nav.${link.text}`) }}
          </a>
        </v-btn>
      </v-toolbar-items>
      <v-spacer></v-spacer>
      <v-menu offset-y v-if="connect">
        <template v-slot:activator="{ on, attrs }">
          <div class="mr-md-4 mr-2 lightenGrey pointer br-8 py-md-1 px-md-2 fz-14 code" v-on="on" v-bind="attrs">
            <div class="d-flex align-center ">
              <v-img class="flex-grow-0 mr-1" src="@/assets/img/m1.svg" height="14" width="14"></v-img>
              <span>{{address.substring(0,6) + '...' + address.substring(address.length-4)}}</span>
            </div>
            <div class="">Binance Smart Chain mainnet</div>
          </div>
        </template>
        <v-list class="pa-0">
          <v-list-item class="pa-0">
            <v-list-item-content class="pointer pa-0" @click="onDisConnect">
              <v-btn class="red--text" text block>Disconnect</v-btn>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-btn width="106" v-if="!connect" class="primary hidden-md-and-down" @click="onConnect"><span class="white--text fz-12">Connect Wallet</span></v-btn>
      <v-btn width="106" v-else class="primary hidden-md-and-down"><span class="white--text fz-12">Connected</span></v-btn>
      <v-icon class="font-weight-bold black--text hidden-md-and-up" @click="onToggleDrawer">mdi-menu</v-icon>
    </v-app-bar>
    <v-main class="">
      <router-view/>
    </v-main>
    <w-footer></w-footer>
    <v-navigation-drawer
        v-if="drawer"
        v-model="drawer"
        absolute
        right
        temporary
        class="lightenGrey fz-12"
    >
      <v-list class="">
        <v-list-item>
          <v-list-item-content><a href="" class="black--text text-decoration-none">Call Wallet</a></v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content><a href="" class="black--text text-decoration-none">Call Explorer</a></v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-btn v-if="!connect" class="primary" @click="onConnect">Collect Wallet</v-btn>
            <v-btn v-else class="primary">Connected</v-btn>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

  </v-app>
</template>

<script>
import {mapState} from 'vuex';
import WFooter from '@/components/Footer';
import api from './api/index'

  export default {
    components: {WFooter},
    data: () => ({
      links: [
        {text: 'Call Wallet', url: 'https://wallet.callchain.cc'},
        {text: 'Call Explorer', url: 'http://block.callchain.cc'},
      ],
      lang: 'zh-CN',
      langTxt: '中文',
      langArr: [{text: '中文', value: 'zh-CN'},{text: 'English', value: 'en'}],
      activeIdx: 0,
      drawer: false,
      group: null,
    }),
    
    computed: mapState({
      connect: (state) => state.connect,
      address: (state) => state.address
    }),

    methods: {
      async onConnect() {
        let ret = await api.getAccountInfo('metamask');
        if (typeof ret === 'string') {
          console.error(ret);
        } else {
          this.$store.dispatch('login', ret);
        }        
      },
      onDisConnect() {
        this.$store.commit('logout');
      },
      onChangeLang({value, text}) {
        this.lang = value;
        this.langTxt = text;
        this.$i18n.locale = value;
      },
      onRoute(url, idx) {
        window.open(url, '_blank');
      },
      onToggleDrawer() {
        this.drawer = !this.drawer;
      },
    },

    mounted() {
      this.$i18n.locale = this.lang;
    }
  }
</script>

<style lang="scss">
  #app {
    background-color: rgb(245, 245, 245);
    .nav.active {
      border-bottom: 2px solid #c91c46;
    }

    .drawer {
      top: 0!important;
      max-height: none;
      height: 100vh!important;
    }
    .v-btn {
      text-transform: none;
    }

    .container--fluid {
      max-width: 1440px;
    }
    .c-1200 {
      max-width: 1200px;
    }

    @media screen and (max-width: 1000px) {
      .code {
        font-size: 12px;
        padding: 2px 8px;
        //display: none;
      }
    }

  }
  .br-8 {border-radius: 8px;}
  .br-16 {border-radius: 16px;}
  .pointer {cursor: pointer;}
  .max-450 {max-width: 450px;}
  .max-360 {max-width: 360px;}
  .max-1200 {max-width: 1200px;}
  .max-1100 {max-width: 1100px;}
  .center-v-x {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .center-h-x {
    display: flex;
    justify-content: center;
  }
  .wt-10 {width: 100%;}
  .wt-5 {width: 50%;}
  .wt-86 {width: 86%;}
  .h-50 {height: 50px;}
  .fz-12 {font-size: 12px;}
  .fz-14 {font-size: 14px;}
  .fz-16 {font-size: 16px;}
  .fz-18 {font-size: 18px;}
  .fz-20 {font-size: 20px;}
  .fz-24 {font-size: 24px;}
  .fz-30 {font-size: 30px;}
</style>

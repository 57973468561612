import Vue from 'vue'
import Vuex from 'vuex'

import BN from 'bignumber.js'
const ZERO = new BN(0)
import api from '../api/index'

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    connect: false,
    address: '',
    balance: ZERO,
  },
  mutations: {
    login(state, data) {
      state.connect = true
      state.address = data.account
    },
    logout(state) {
      state.connect = false
      state.address = ''
    },
    updateBalance(state, bal) {
      state.balance = bal
    }
  },
  actions: {
    async login(context, data) {
      context.commit('login', data)
    }
  },
  modules: {
  }
})


async function fetchUserInfo() {
  if (store.state.address !== '') {
    let bal = await api.balanceOf(store.state.address)
    store.commit('updateBalance', bal)
  }
}

// first init
fetchUserInfo();

setInterval(async function() {
  await fetchUserInfo()
}, 5000)


export default store

const Web3 = require('web3');
const ERC20 = require('./ERC20.json');
const CROSS = require('./Cross.json');
const BN = require('bignumber.js');
const ZERO = new BN(0);

// testnet: https://data-seed-prebsc-1-s1.binance.org:8545/
const rpcUrl = "https://bsc-dataseed1.defibit.io/";
// testnet: 97
const CHAINID = 56;
const CALL_ADDRESS = "0xCf7c87CB192BF21660B360F3f270400a42070aea";
const CROSS_ADDRESS = "0xa3c73A548b4dd56ecBE1bF95DFfD907b01e2B3B4";

const web3 = new Web3(rpcUrl);
const crossContract = new web3.eth.Contract(CROSS, CROSS_ADDRESS);

function isValidAddr(addr) {
    return /^c[1-9A-HJ-NP-Za-km-z]{25,34}$/.test(addr);
}

async function balanceOf(address) {
    let contract = new web3.eth.Contract(ERC20, CALL_ADDRESS);
    let decimals = await contract.methods.decimals().call();
    let balance = await contract.methods.balanceOf(address).call();
    balance = new BN(balance).div(10 ** Number(decimals));
    return balance;
}

async function checkTransaction(hash) {
    let tx = await web3.eth.getTransactionReceipt(hash);
    return tx ? tx.status : false;
}

async function sendTransaction(contract, data, from_address) {
    console.log('send transaction ...')
    let chainId = Number(CHAINID);
    let gasPrice = await web3.eth.getGasPrice();
    const gas = await web3.eth.estimateGas({to: contract, data: data, from: from_address});
    const nonce = await web3.eth.getTransactionCount(from_address);

    const transactionParameters = {
        nonce: web3.utils.toHex(nonce),
        gasPrice: web3.utils.toHex(gasPrice),
        gas: web3.utils.toHex(gas),
        to: contract,
        from: from_address,
        value: '0x00',
        data: data,
        chainId: chainId
    };
    console.dir(transactionParameters);
    // mathwallet txHash is {}
    let txHash;
    if (window.BinanceChain) {
        txHash = await window.BinanceChain.request({
            method: 'eth_sendTransaction',
            params: [transactionParameters],
        });
    }
    else if (window.ethereum.isMathWallet)
    {
        txHash = await window.ethereum.request({
            method: 'eth_sendTransaction',
            params: [transactionParameters],
        });
    }
    else {
        // console.dir(window.ethereum.request);
        txHash = await window.ethereum.request({
            method: 'eth_sendTransaction',
            params: [transactionParameters],
        });
    }

    return txHash;
}

// amount is BN
async function approve(amount, from_address) {
    let contract = new web3.eth.Contract(ERC20, CALL_ADDRESS);
    let decimals = await contract.methods.decimals().call();
    let _amount = amount.times(10 ** Number(decimals));
    let data = contract.methods.approve(CROSS_ADDRESS, _amount.toFixed(0, 0)).encodeABI();
    return await sendTransaction(CALL_ADDRESS, data, from_address);
}

// amount is BN
async function checkAllowance(from_address, amount) {
    let contract = new web3.eth.Contract(ERC20, CALL_ADDRESS);
    let allow = await contract.methods.allowance(from_address, CROSS_ADDRESS).call();
    // console.log('checkAllowance,  owner=' + from_address + ', spender=' + CROSS_ADDRESS + ', allowed amount=' + allow);
    // console.log(allow);
    allow = new BN(allow);
    let decimals = await contract.methods.decimals().call();
    let _amount = amount.times(10 ** Number(decimals));
    return allow.isGreaterThanOrEqualTo(_amount);
}

async function getAccountInfo(wallet) {
    switch(wallet)
    {
        case 'tokenpocket':
        case 'trustwallet':
        case 'metamask': {
            if (typeof window.ethereum === 'undefined') {
                return 'MetaMask is NOT installed!';
            }
            let chainId = await window.ethereum.request({ method: 'eth_chainId' });
            if (wallet === 'trustwallet') {
                chainId = window.ethereum.chainId;
            }
            if (Number(chainId) !== Number(CHAINID)) {
                return 'Wrong Network: ' + chainId;
            }
            const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
            const account = accounts.length > 0 ? accounts[0] : null;
            if (!account) {
                return 'Metamask is locked';
            }
            return {account: account, rpc: window.ethereum};
        }
        case 'mathwallet': {
            if (typeof window.ethereum === 'undefined') {
                console.error('MathWallet is NOT installed!')
                return undefined;
            }
            if (window.ethereum.address === '') {
                return 'MathWallet is locked';
            }
            return {account: window.ethereum.address, rpc: window.ethereum.rpc.rpcUrl};
        }
        case 'binancewallet': {
            if (typeof window.BinanceChain === 'undefined') {
                return 'BinanceChainWallet is NOT installed!';
            }
            if (Number(window.BinanceChain.chainId) !== Number(CHAINID)) {
                return 'Wrong Network!';
            }
            const accounts = await window.BinanceChain.request({ method: 'eth_requestAccounts' });
            const account = accounts.length > 0 ? accounts[0] : null;
            if (!account) {
                return 'BinanceChainWallet is locked';
            }
            return {account: account, rpc: ''};
        }
    }
}

// amount is BN
// to is call address
async function goHome(to, amount, from_address) {
    console.log('go home...')
    let contract = new web3.eth.Contract(ERC20, CALL_ADDRESS);
    let decimals = await contract.methods.decimals().call();
    let _amount = amount.times(10 ** Number(decimals));
    console.log('_amount=' + _amount);
    let data = crossContract.methods.goHome(to, _amount.toFixed(0)).encodeABI();
    console.log('data=' + data);
    return await sendTransaction(CROSS_ADDRESS, data, from_address);
}

export default {
    isValidAddr,
    getAccountInfo,

    balanceOf,
    approve,
    checkAllowance,
    checkTransaction,
    sendTransaction,

    goHome
}



export const m = {
    hello: '你好'
}

export const nav = {
    DASHBOARD: '仪表盘',
    CONVERT: '转换',
    REBALANCE: '再平衡',
    FARM: '矿场',
    FARM2: '矿场2',
    STAKE: '质押',
    INFO: '统计信息',
}


export const info = {
    txt1: '市场',
    txt2: 'PUSD当前供应',
    txt3: 'PEGS当前供应',
    txt4: '总分红',
    txt5: '质押情况',
    txt6: 'PUSD在USDC/PUSD池比例',
    txt7: 'PUSD在PEGS/PUSD池比例',
    txt8: '实际流通PUSD',
    txt9: 'PUSD在PEGS/PUSD池比例',
    txt10: 'PEGS在Staking池比例',
    txt11: '实际流通PEGS',
    txt12: '合约地址',
}
